import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";
import Layout from "../components/doc-layout";

const Script = ({ data, className, location }) => {
  const script = data.allFountain.nodes[0];
  return (
    <Layout location={location}>
      <div className={className}>
        <div className="header" dangerouslySetInnerHTML={{ __html: script.title_page_html }} />
        <div className="scripty" dangerouslySetInnerHTML={{ __html: script.script_html }} />
        <div style={{ color: "#111", display: "block" }}>(C) Sophia Wood &middot; {script.draft_date}</div>
      </div>
    </Layout>
  );
};

export default styled(Script)`
  -webkit-touch-callout: none;
  user-select: none;
  width: 100%;
  color: #222;
  font: Courier, "Courier New", monospace;
  letter-spacing: 0 !important;
  font-family: "Courier Final Draft", "Courier New", Courier, monospace, Courier New, monospace;
  line-height: 107.5%;
  margin-bottom: 25px;
  text-align: left;
  z-index: 100;
  font-size: 12pt;

  & .header li,
  & .header p,
  & .header ul {
    margin: 0;
    padding: 0;
  }

  & .header p {
    font-size: 14px;
    line-height: 2;
    margin-top: 10px;
    text-align: center;
  }

  & .scripty,
  & .header {
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    max-width: 640pt;
    padding-left: 7%;
    padding-right: 7%;
    padding-bottom: 20pt;
    padding-top: 20pt;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10pt;
    line-height: 2;
  }

  & .header {
    color: #333;
    margin-bottom: 20pt;
    margin-top: 10pt;
  }

  & .scripty > p {
    line-height: 1.6;
  }

  & h2 {
    font-size: 16pt;
  }

  & .dialogue > h4 {
    font-size: 13pt;
  }

  & .header h1 {
    text-align: center;
  }

  & .header p.credit {
    text-align: center;
  }

  & .header p.author,
  & .header p.authors {
    text-align: center;
  }

  & .header p.source {
    text-align: center;
    /* width: 90.5%; */
  }

  & .header p.notes {
    text-align: right;
  }

  & .dialogue {
    margin-left: auto;
    margin-right: auto;
    width: 58%;
    margin-bottom: 10pt;
    padding-bottom: 3pt;
  }

  & .dialogue h4 {
    text-align: center;
    margin-bottom: 0;
  }

  & .dialogue p.parenthetical {
    margin-bottom: 0;
    margin-top: 0;
    font-style: italic;
    text-align: center;
  }

  & .dialogue p {
    padding-top: 2pt;
    margin-bottom: 0;
    margin-top: 0;
    line-height: 1.5;
  }

  & div.dual-dialogue {
    margin: 2em 0 0.9em 2%;
    width: 95%;
  }

  & div.dual-dialogue .dialogue {
    display: inline-block;
    margin: 0;
    width: 45%;
  }

  & div.dual-dialogue .dialogue h4 {
    margin-top: 0;
  }

  & div.dual-dialogue .dialogue.right {
    float: right;
  }

  & p.centered {
    text-align: center;
  }

  & p.synopsis,
  & p.section {
    color: #333;
    font-weight: bold;
    /* margin-left: -20px; */
  }

  & span.italic {
    font-style: italic;
  }

  & span.bold {
    font-weight: 700;
  }

  & h3 {
    font-weight: bold;
  }

  & .note {
    opacity: 0.5;
    font-style: italic;
  }

  & .underline {
    text-decoration: underline;
  }
`;

export const pageQuery = graphql`
  query($slug: String!) {
    allFountain(filter: { fileAbsolutePath: { eq: $slug } }) {
      nodes {
        title
        draft_date
        title_page_html
        script_html
        scenes
        source
      }
    }
  }
`;
